import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAbVgePOhshUGYMnYKLS6EhutydhRo4j04",
  authDomain: "push-notification-b22b1.firebaseapp.com",
  projectId: "push-notification-b22b1",
  storageBucket: "push-notification-b22b1.appspot.com",
  messagingSenderId: "682159514734",
  appId: "1:682159514734:web:5fdfacfe6ba964d612f27f",
};

const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);
